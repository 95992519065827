import React, { Component } from 'react'
import Sidebar from '../includes/Sidebar'
import TopBar from '../includes/TopBar';
import Footer from '../includes/Footer'
import API from '../api/API';
import { Store  } from 'react-notifications-component';




export default class Cache extends Component {




    clearCache=(type)=>{

        if(type==='navbar'){
            API.get('/menu/clearcache')
            .then(response=>{
                console.log(response.data)

                Store.addNotification({
                    title: 'Well done',
                    message: 'You successfully logged in to this website',
                    type: 'success',                         // 'default', 'success', 'info', 'warning'
                    container: 'top-right',                // where to position the notifications
                    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                    dismiss: {
                      duration: 3000
                    }
                })
            })
        }

        if(type==='home'){
            API.get('/blog/clearcache')
            .then(response=>{
                console.log(response.data)

                Store.addNotification({
                    title: 'Well done',
                    message: 'You successfully logged in to this website',
                    type: 'success',                         // 'default', 'success', 'info', 'warning'
                    container: 'top-right',                // where to position the notifications
                    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                    dismiss: {
                      duration: 3000
                    }
                })
            })
        }


        if(type==='blogs'){
            API.get('/blog/clearcache')
            .then(response=>{
                console.log(response.data)
                Store.addNotification({
                    title: 'Well done',
                    message: 'You successfully logged in to this website',
                    type: 'success',                         // 'default', 'success', 'info', 'warning'
                    container: 'top-right',                // where to position the notifications
                    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                    dismiss: {
                      duration: 3000
                    }
                })
            })
        }



    }


    render() {
        return (
            <>
            <div id="wrapper">
            <Sidebar />
            <div className="content-page">
            <div className="content">             
            <TopBar pagename="Cache" />
            <div className="page-content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                
                                <button className='btn btn-primary m-2' onClick={()=>this.clearCache('navbar')}>Clear Navbar Cache</button>
                                <button className='btn btn-primary m-2' onClick={()=>this.clearCache('home')}>Clear Home Page Cache</button>
                                <button className='btn btn-primary m-2' onClick={()=>this.clearCache('blogs')}>Clear Blog Cache</button>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <Footer />
            </div>
            </div>
            </>
        )
    }
}
