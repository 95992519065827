import React, { Component } from 'react'
import { connect } from 'react-redux'
import Sidebar from '../../includes/Sidebar'
import TopBar from '../../includes/TopBar';
import Footer from '../../includes/Footer'
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import {fetchBlogs,fetchAllBlogCategory} from '../../actions'
import Table from 'react-bootstrap/Table'
import LoadingScreen from '../../includes/LoadingScreen'
import Moment from 'react-moment';
import { MdDelete, MdModeEdit, MdVisibility } from "react-icons/md";
import API from '../../api/API'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import cookie from 'react-cookies'


export class BlogAll extends Component {
    
    componentDidMount() {
        this.props.fetchBlogs();
        this.props.fetchAllBlogCategory();

        const script = document.createElement("script");
        script.src = "/assets/js/dynamitable.jquery.min.js";
        script.async = true;
        document.body.appendChild(script);
    }


    handleCheckboxChange = (blog,mainstatus) => {

        if(mainstatus===true){
            blog.status=false;
        }
        if(mainstatus===false){
            blog.status=true;
        }

        blog.id=blog._id
        API.post('/blog/updatenew',blog)
        .then(response=>{
            this.props.fetchBlogs();
            this.props.fetchAllBlogCategory();
        })

    }


    handleDelete = e => {
        var mpropsh = this.props;

        Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this imaginary file!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#f27474',
            confirmButtonText: 'Yes, I am sure!',
            cancelButtonText: "No, cancel it!"
         }).then(
               function (isConfirm) { 
                if (isConfirm.value){
                    API.patch(`/blog/${e}`)
                    .then(response=>{
                        if(response.data.response === 'true'){
                            Swal.fire('Success', 'Successfully Created', 'success')
                            mpropsh.fetchBlogs()
                        }else{
                            alert('Failed')
                        }
                    })
                   } else {
                    // Swal.fire("Failed", "Please try again", "error");
                    
                   }
                },
               function () { 
                Swal.fire('Cancelled')
        });
    }

    render() {
        var userinfo=cookie.load('userdata');
        var blogs=this.props.blogs;
        var categories = this.props.categories;
        if(!blogs){
            return(
                <LoadingScreen />
            )
        }
        if(!categories){
          return(
              <LoadingScreen />
          )
       }
        return (
            <React.StrictMode>
            <div id="wrapper">
            <Sidebar />
            <div className="content-page">
            <div className="content">             
            <TopBar pagename="Blog All" />
            <div className="page-content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                
                              <div class="table-responsive">
                              <Table className="js-dynamitable" striped bordered hover >
                              <thead>
                                <tr>
                                  <th className="my_width100">Image</th>
                                  <th className="my_width150">Title</th>
                                  <th className="my_width150">Category</th>
                                  <th className="my_width150">Date Published</th>
                                  <th className="my_width150">Author</th>
                                  <th className="my_width100">Status</th>
                                  <th className="my_width100"></th>
                                </tr>
                                
                                <tr>
                                  <th>
                                    {/* IMAGE */}
                                  </th>
                                  <th><input className="js-filter form-control" type="text"  /></th>
                                  <th><input className="js-filter form-control" type="text"  /></th>
                                  <th><input className="js-filter form-control" type="text"  /></th>
                                  <th><input className="js-filter form-control" type="text"  /></th>
                                  <th><input className="js-filter form-control" type="text"  /></th>

                                  <th></th>
                                </tr>

                              </thead>
                              <tbody>
                                  {blogs.map((blog)=>{
                                      return(
                                          <tr key={blog._id}>
                                              <td> <img src={blog.thumbnail_image+'?tr=w-50,h-50,q-20'} width={50} height={50} alt=""/> </td>
                                              <td>{blog.title}</td>
                                              <td>{blog.category.name}</td>
                                              <td><Moment format="YYYY-MM-DD">{blog.createdAt}</Moment></td>
                                              <td>{blog.user_info.name}</td>
                                              <td>
                                                {userinfo.usertype==='Admin'
                                                ?
                                                <label className="switch">
                                                    <input type="checkbox" defaultChecked={blog.status} onClick={()=>this.handleCheckboxChange(blog,blog.status)} />
                                                    <span className="slider round" />
                                                </label>
                                                :
                                                <>
                                                {blog.status?<span class="badge badge-success">Active</span>:<span class="badge badge-warning">Pending...</span>}
                                                </>
                                                }
                                                

                                            
                                              </td>
                                              <td>
                                                {userinfo.usertype==='Admin'
                                                ?
                                                <center>
                                                  <Link exact to={`/blogs/${blog._id}/edit`} className="rcicons ml-1 text-primary cursor-pointer"><MdModeEdit /></Link>
                                                  <Link onClick={()=>this.handleDelete(blog._id)} className="rcicons ml-1 text-danger cursor-pointer"><MdDelete /></Link>
                                                </center>
                                                :
                                                <>
                                                    {userinfo._id===blog.user_info._id
                                                    ?
                                                    <center>
                                                    <Link exact to={`/blogs/${blog._id}/edit`} className="rcicons ml-1 text-primary cursor-pointer"><MdModeEdit /></Link>
                                                    <Link onClick={()=>this.handleDelete(blog._id)} className="rcicons ml-1 text-danger cursor-pointer"><MdDelete /></Link>
                                                    </center>
                                                    :<></>
                                                    }
                                                </>
                                                }
                                                  
                                              </td>
                                          </tr>
                                      )
                                  })}

                              </tbody>
                            </Table>
                            </div>



                          </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <Footer />
            </div>
            </div>
            </React.StrictMode>
        )
    }
}

const mapStateToProps = (state) => ({
    blogs:state.blogs,
    categories:state.blogcategories
})


export default connect(mapStateToProps, {fetchBlogs,fetchAllBlogCategory})(BlogAll)
