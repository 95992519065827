import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import LoadingScreen from './LoadingScreen'
import {fetchAllImages} from '../actions'
import cookie from 'react-cookies'
import { MdDelete, MdModeEdit,MdVisibility } from "react-icons/md";
import {Helmet} from "react-helmet";
import Dropzone from 'react-dropzone';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import API from '../api/API';

export class BlogCreateImageUploadBox extends Component {

    constructor(props){
        super(props)
        this.state={
            imageLoading:false,
            disableDeleteButton:false
        }
    }


    componentDidMount() {
        this.props.fetchAllImages();
        const script = document.createElement("script");
        script.src = "/assets/plugins/datatables/jquery.dataTables.min.js";
        script.src = "/assets/plugins/datatables/dataTables.bootstrap4.min.js";
        script.src = "/assets/plugins/datatables/dataTables.responsive.min.js";
        script.src = "/assets/plugins/datatables/responsive.bootstrap4.min.js";
        script.src = "/assets/pages/datatables.init.js";
        script.async = true;
        document.body.appendChild(script);
    }


    handleDelete=(fileId,id)=>{

        if (window.confirm("Are you sure?"))
        { 
            this.setState({disableDeleteButton:true})
            API.get(`/image/${fileId}/${id}`)
            .then(response=>{
                this.setState({disableDeleteButton:false})
                this.props.fetchAllImages();
                
            })
        }else{
            
        }

        
        
    }

    //image drop uplaod
    onDrop = (files) => {

        var totalfiles=[];
        
        if(files.length>5){
          Swal.fire('Failed', `You can't upload more than 5 file at a time`, 'error')
        }else{
          files.forEach(file => {
            if(file.type==='image/jpg' || file.type==='image/jpeg' || file.type==='image/png' || file.type==='image/webp'|| file.type==='image/svg+xml'){
              if (file.size >= 5242880){
                // message.warning(`${file.path} size is more than 5mb.`)
                Swal.fire('Failed', `${file.path} size is more than 5mb.`, 'error')

  
              }else{
                totalfiles.push(file); 
              }
            }else{
            //   message.warning(`${file.path} is not an image.`)
                Swal.fire('Failed', `${file.path} is not an image.`, 'error')
            }
          });
        }
  
      
        if(totalfiles.length>0){
          
          this.setState({imageLoading:true})
  
          var count=0;
          totalfiles.forEach((file,key) => {
  
            var formData = new FormData();
            formData.append("image", file);
            API.post(`/image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response=>{
                this.props.fetchAllImages();
                
                  count=count+1
                  if(count===totalfiles.length){
                    this.setState({imageLoading:false})
                  }
            })

            // axios.post(`${process.env.backendURL}/product/productimage_add`, formData, {
            //   headers: {
            //     'Content-Type': 'multipart/form-data'
            //   }
            // })
            // .then(response=>{
            //   this.fetchProductInformation('images');
  
            //   count=count+1
            //   if(count===totalfiles.length){
            //     this.setState({imageLoading:false})
            //   }
            // })
    
          });
        }else{
          this.setState({
            imageLoading:false
          })
        }
  
      
      }




    render() {
        const images = this.props.images;
        if(!images){
            return(
                <LoadingScreen />
            )
        }
        
        var cookieuser = cookie.load('userdata');
        var cookieuserid=cookieuser._id;
        return (
            <React.StrictMode>
           

            <div className="page-content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                

                                

                            {this.state.imageLoading
                                ?
                                <center>
                                    <img src='https://www.effia.com/themes/custom/effia_theme/images/throbber.gif' style={{height:'73px', margin:'60px'}} />
                                </center>
                                :
                                <Dropzone 
                                    onDrop={this.onDrop}
                                    >
                                    {({getRootProps, getInputProps}) => (
                                        <section className="dropzonecontainer">
                                        <div {...getRootProps({className: 'dropzone'})}>
                                            <input {...getInputProps()} />
                                            <center>
                                            <p>
                                            
                                            <img src='https://www.pngplay.com/wp-content/uploads/8/Upload-Icon-Logo-Transparent-File.png' width={300} />
                                           
                                            Drag and drop images here, or click to select files</p>
                                            </center>
                                            <br/>
                                        </div>
                                        </section>
                                    )}
                                </Dropzone>
                            }

                                <br/>
                                <br/>
                                <br/>

                                <div className='row'>
                                    {images.map((img,key)=>{
                                        return(
                                            <div className='col-md-3 mt-4'>
                                                <div style={{border:'1px solid #dadada',padding: '9px', borderRadius: '5px'}}>
                                                    <img src={img.url+'?tr=w-200,h-200,q-50'} style={{width:'100%',maxHeight:'200px'}} />
                                                    <hr />
                                                    <a className='cursor-pointer btn btn-sm btn-primary text-white' onClick={()=>this.props.handleSelectImage(img.url)}>
                                                        Select Image
                                                    </a>
                                                    <Link onClick={()=>this.handleDelete(img.fileId,img._id)} className="ml-1 cursor-pointer btn btn-sm btn-danger" disabled={this.state.disableDeleteButton}>
                                                        Delete
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                
                                        {/* <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>URL</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {images.map((img,key)=>{
                                                return(
                                                    <tr key={key}>
                                                        <td><img src={img.url+'?tr=w-50,h-50,q-50'} height={50} width={50} /></td>
                                                        <td>{img.url}</td>
                                                        <td><Link onClick={()=>this.handleDelete(img.fileId,img._id)} className="text-danger ml-1" disabled={this.state.disableDeleteButton}><MdDelete /></Link></td>
                                                    </tr>
                                                )
                                            })}
                                            
                                            
                                            </tbody>
                                        </table> */}
                                        






                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

           
            </React.StrictMode>
        )
    }
}

const mapStateToProps = (state) => ({
    images:state.images
})



export default connect(mapStateToProps, {fetchAllImages})(BlogCreateImageUploadBox)
