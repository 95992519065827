import React, { Component } from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Sidebar from '../../includes/Sidebar'
import TopBar from '../../includes/TopBar';
import Footer from '../../includes/Footer'
import CKEditor from 'ckeditor4-react-advanced';
import { VscStarFull } from "react-icons/vsc";
import {fetchAllBlogCategory,fetchAllBlogSubCategory} from '../../actions'
import SimpleReactValidator from 'simple-react-validator';
import API from '../../api/API'
import {Helmet} from "react-helmet";
import LoadingScreen from '../../includes/LoadingScreen'
import _ from 'lodash'
import BlogCreateImageUploadBox from '../../includes/BlogCreateImageUploadBox';
import { MdOutlineContentCopy } from "react-icons/md";


export class BlogEdit extends Component {

    constructor(props){
        super(props)
        this.state={
            showImageBox:false,
            selectImageField:'',
            loadingscreen:true,
            status:'',
            id:'',
            url:'',
            title:'',
            description:'',
            category:[],
            subcategory:[],

            image:'',
            tempimage:'',
            content:'',
            contentreadtime:0,

            metatitle:'',
            metadescription:'',
            metakey:'',
            metakey_array:[],


            thumbnail_image:'',
            breadcrumb_image:'',
            banner_image:'',
            imagetitle:'',
            imagealt:'',
            banner_color:'#f81616',

            
        }
        this.validator = new SimpleReactValidator();
        this.onEditorChange=this.onEditorChange.bind(this);
        this.handleTextChange=this.handleTextChange.bind(this);
        this.handleImageChange=this.handleImageChange.bind(this);
        
    }

    componentDidMount() {
        this.props.fetchAllBlogCategory();
        this.props.fetchAllBlogSubCategory();


        API.get(`/blog/${this.props.match.params.id}`)
        .then(response=>{
            this.setState({
                loadingscreen:false,
                status:response.data.data.status,
                url:response.data.data.blogurl,
                id:response.data.data._id,
                title:response.data.data.title,
                description:response.data.data.description,
                category:response.data.data.category,
                subcategory:response.data.data.subcategory,
                tempimage:response.data.data.image,
                content:response.data.data.content,
                contentreadtime:response.data.data.contentreadtime,

                
                metatitle:response.data.data.metatitle,
                metadescription:response.data.data.metadescription,
                metakey:response.data.data.metakey,
                metakey_array:response.data.data.metakey_array,
                thumbnail_image:response.data.data.thumbnail_image,
                breadcrumb_image:response.data.data.breadcrumb_image,
                banner_image:response.data.data.banner_image,
                imagetitle:response.data.data.imagetitle,
                imagealt:response.data.data.imagealt,
                banner_color:response.data.data.banner_color,
            })
        // console.log(this.state);

        })
    }


    calculateReadingTime = (textdata) => {
        const text = textdata;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time;
        // this.setState({
        //     contentreadtime:time
        // })
        // console.log(time)
        // document.getElementById("time").innerText = time;
    }



    handleTextChange(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    onEditorChange( evt ) {
        this.setState( {
            content: evt.editor.getData(),
            contentreadtime:this.calculateReadingTime(evt.editor.getData())
        } );

    }

    handleImageChange(e){
        this.setState({
            image:e.target.files[0],
            tempimage:URL.createObjectURL(e.target.files[0])
        })
    }


    handleSubmit = e => {
        e.preventDefault();

        var mpropsh = this.props.history;

        if (this.validator.allValid()) {
            console.log(this.state);

            // let formData = new FormData();
            // formData.append('title',this.state.title);
            // formData.append('blogurl',this.state.url);
            // formData.append('description',this.state.description);
            // formData.append('category',this.state.category);
            // formData.append('image',this.state.image);
            // formData.append('content',this.state.content);
            // formData.append('metatitle',this.state.metatitle);
            // formData.append('metadescription',this.state.metadescription);
            // formData.append('metakey',this.state.metakey);

            
            // const config = {
            //     headers: {'content-type':'multipart/formdata'}
            // }

            // API.put(`/blog/${this.state.id}`,formData,config)
            // .then(response=>{

            //     if(response.data.response === 'true'){

            //         Swal.fire('Success', 'Successfully Created', 'success')
            //         mpropsh.push('/blogs');

            //     }else{
            //         alert('Failed')
            //     }


            // })

            var data=this.state;
            data.blogurl = this.state.url;
            // data.categoryurl = '/blogs/'+this.state.category.toLowerCase();
            // data.totalurl = '/blogs/'+this.state.category.toLowerCase()+'/'+this.state.blogurl;



            if(data.thumbnail_image===''){
                Swal.fire('error', 'Please select thumbnail_image', 'error')
                window.scrollTo(0,0);
            }else if(data.breadcrumb_image===''){
                Swal.fire('error', 'Please select breadcrumb_image', 'error')
                window.scrollTo(0,0);
            }else if(data.banner_image===''){
                Swal.fire('error', 'Please select banner_image', 'error')
                window.scrollTo(0,0);
            }else{
                API.post('/blog/updatenew',data)
                .then(response=>{
                    // console.log(response.data.response)

                    if(response.data.response === true){

                        Swal.fire('Success', 'Successfully Created', 'success')
                        mpropsh.push('/blogs');

                    }else{
                        alert('Failed')
                    }


                })
            }




            // API.post('/blog/updatenew',data)
            // .then(response=>{
            //     // console.log(response.data.response)

            //     if(response.data.response === true){

            //         Swal.fire('Success', 'Successfully Created', 'success')
            //         mpropsh.push('/blogs');

            //     }else{
            //         alert('Failed')
            //     }


            // })
            


        }else{
            this.validator.showMessages();
            this.forceUpdate();
        }

    }


    handleAddMetaKey = e => {
        e.preventDefault();
        var data= document.getElementById('metaley77').value;
        if(data===''){

        }else{
            var mainarray=this.state.metakey_array;
        
            mainarray.push(data);
            this.setState({
                metakey_array:mainarray,
                metakey:mainarray.join(', ')
            })
            var data= document.getElementById('metaley77').value='';
        }

    }



    handleSelectImage = url => {
        // window.scrollTo(0,0);
        window.scrollTo(0,300);

        this.setState({
            showImageBox:false,
            // [this.state.selectImageField]:url,
            thumbnail_image:url,
            breadcrumb_image:url,
            banner_image:url,
            selectImageField:''
        })
    }

    handleSelectImagePopup = name => {
        this.setState({
            selectImageField:name,
            showImageBox:true
        })
    }


    removeArray = name => {
        var newArr = this.state.metakey_array.filter(w => w !==name);
        this.setState({
            metakey_array:newArr,
            metakey:newArr.join(', ')
        })
    }



    handleAddCategory=(event)=>{
        var isChecked=event.target.checked;
        var catid=event.target.getAttribute('catid');
        var category = this.state.category;
        if(isChecked){
            if(category.includes(catid)){
            }else{
                category.push(catid)
            }
        }else{
            if(category.includes(catid)){
                category = category.filter(w => w !==catid);
            }else{
            }
        }
        this.setState({
            category
        })
    }


    handleAddSubCategory=(event)=>{
        var isChecked=event.target.checked;
        var catid=event.target.getAttribute('catid');
        var subcatid=event.target.getAttribute('subcatid');

        var category = this.state.category;
        var subcategory = this.state.subcategory;


        if(isChecked){
            if(subcategory.includes(subcatid)){
            }else{
                subcategory.push(subcatid)
                if(category.includes(catid)){
                }else{
                    category.push(catid)
                }
            }
        }else{
            if(subcategory.includes(subcatid)){
                subcategory = subcategory.filter(w => w !==subcatid);
            }else{
            }
        }
        this.setState({
            subcategory
        })
    }




    
    render() {
        var urlLower = this.state.title.toLowerCase();
        var url = urlLower.replace(/ /g,'-');

        if(this.state.loadingscreen){
            return(
                <LoadingScreen />
            )
        }

        var blogcategories = this.props.blogcategories;
        var blogsubcategories = this.props.blogsubcategories;

        if(!blogcategories){
            return(
                <LoadingScreen />
            )
        }

        return (
            
            <div id="wrapper">
            <Helmet>
                <script src="/assets/plugins/bootstrap-filestyle/js/bootstrap-filestyle.min.js"></script>
            </Helmet>
            <Sidebar />
            <div className="content-page">
            <div className="content">             
            <TopBar pagename="Blog Edit" />
            {this.state.showImageBox
            ?
            <>
            <BlogCreateImageUploadBox handleSelectImage={this.handleSelectImage} />
            </>
            :
            <>
            <div className="page-content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {this.state.blogurl}
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        
                                        {/* <div className='col-md-4 mb-4' onClick={()=>this.handleSelectImagePopup('thumbnail_image')}>
                                            <label>Blog Image</label>
                                            {this.state.thumbnail_image===''
                                            ?
                                            <div className='imagebox458'>
                                                <center>Thumbnail Image</center>
                                            </div>
                                            :
                                            <img src={this.state.thumbnail_image+'?tr=w-200,h-200,q-50'} className='cursor-pointer' style={{width:'100%',maxHeight:'200px'}} />
                                            }
                                        </div>
                                        <div>
                                            <label>Preview</label>
                                            {this.state.thumbnail_image===''
                                            ?
                                            <div className='imagebox458'  style={{backgroundColor:this.state.banner_color}}>
                                                <center>Thumbnail Image</center>
                                            </div>
                                            :
                                            <div  style={{backgroundColor:this.state.banner_color}}>
                                                <img src={this.state.thumbnail_image+'?tr=w-200,h-200,q-50'} className='cursor-pointer' style={{width:'100%',maxHeight:'200px'}} />
                                            </div>
                                            
                                            }
                                        </div> */}



                                        {/* <div className='col-md-4 mb-4' onClick={()=>this.handleSelectImagePopup('breadcrumb_image')}>
                                            {this.state.breadcrumb_image===''
                                            ?
                                            <div className='imagebox458'>
                                                <center>Breadcrumb Image</center>
                                            </div>
                                            :
                                            <img src={this.state.breadcrumb_image+'?tr=w-200,h-200,q-50'} className='cursor-pointer' style={{width:'100%',maxHeight:'200px'}} />
                                            }
                                        </div>
                                        <div className='col-md-4 mb-4' onClick={()=>this.handleSelectImagePopup('banner_image')}>
                                            {this.state.banner_image===''
                                            ?
                                            <div className='imagebox458'>
                                                <center>Blog Image</center>
                                            </div>
                                            :
                                            <img src={this.state.banner_image+'?tr=w-200,h-200,q-50'} className='cursor-pointer' style={{width:'100%',maxHeight:'200px'}} />
                                            }
                                        </div> */}

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Title</label>
                                                <input type="text" className="form-control" name="title" value={this.state.title}  onChange={this.handleTextChange} />
                                                {/* <h6 className="text-danger">{this.validator.message('name', this.state.title, 'required')}</h6> */}

                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>URL 
                                                        {/* ({`blog.qtonix.com/${this.state.url}`}) */}
                                                    </label>
                                                    <div className='d-flex'>
                                                    <span style={{fontSize: '15px',marginTop: '8px',marginRight: '3px'}}>blog.qtonix.com/</span>
                                                    <input type="text" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className="form-control" name="url" value={this.state.url} onChange={this.handleTextChange}  required/>
                                                    <span className='btn btn-primary btn-sm' onClick={()=>navigator.clipboard.writeText(`blog.qtonix.com/blog/${this.state.url}`)}><MdOutlineContentCopy /></span>

                                                    </div>
                                                    {/* <h6 className="text-danger">{this.validator.message('url', this.state.url, 'required')}</h6> */}
                                                </div>
                                            </div>
                                        

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <textarea className="form-control" rows="3" name="description" value={this.state.description} onChange={this.handleTextChange}  required></textarea>
                                                {/* <h6 className="text-danger">{this.validator.message('description', this.state.description, 'required')}</h6> */}

                                            </div>
                                        </div>

                                        <div className='col-md-12 mt-4 mb-4'>
                                        <label>Category / Subcategory</label>
                                            <div className='row'>
                                                {blogcategories.map((cate)=>{
                                                    return(
                                                        <div className='col-md-3' key={cate._id}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id={`defaultCheck1${cate._id}`} catid={cate._id} onClick={this.handleAddCategory} checked={this.state.category.includes(cate._id)?true:false} />
                                                                <label className="form-check-label" htmlFor={`defaultCheck1${cate._id}`}>
                                                                    <b>{cate.name}</b>
                                                                </label>
                                                            </div>
                                                            <div className='ml-3'>
                                                            {cate.subcategories.map((subcat)=>{
                                                                return(
                                                                    <div className="form-check" key={subcat._id}>
                                                                        <input className="form-check-input" type="checkbox" defaultValue id={`defaultCheckass1${cate._id}`} catid={cate._id} subcatid={subcat._id} onClick={this.handleAddSubCategory} checked={this.state.subcategory.includes(subcat._id)?true:false} />
                                                                        <label className="form-check-label" htmlFor={`defaultCheckass1${cate._id}`}>
                                                                            {subcat.name}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })}
                                                            </div>


                                                        </div>  
                                                    )
                                                })}
                                                
                                            </div>

                                        </div>


                                        {/* <div className='col-md-12 mt-3 mb-3'>
                                            <label>Category</label>
                                            <div  className="form-group">
                                                {blogcategories.map((cate)=>{
                                                    return(
                                                        <div className="form-check form-check-inline" key={cate._id}>
                                                            <input className="form-check-input" type="checkbox" id={`inlineCheckbox1${cate._id}`}/>
                                                            <label className="form-check-label" htmlFor={`inlineCheckbox1${cate._id}`}>{cate.name}</label>
                                                        </div>  
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className='col-md-12 mt-3 mb-3'>
                                            <label>SubCategory</label>
                                            <div  className="form-group">
                                                {blogsubcategories.map((cate)=>{
                                                    return(
                                                        <div className="form-check form-check-inline" key={cate._id}>
                                                            <input className="form-check-input" type="checkbox" id={`inlineCheckbox1s${cate._id}`}/>
                                                            <label className="form-check-label" htmlFor={`inlineCheckbox1s${cate._id}`}>{cate.name}</label>
                                                        </div>  
                                                    )
                                                })}
                                            </div>
                                        </div> */}

                                        {/* <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Category</label>
                                                <select class="form-control" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} name="category" value={this.state.category} onChange={this.handleTextChange}  required>
                                                    <option value="">Select Category</option>
                                                    {blogcategories.map((cate)=>{
                                                        return(
                                                            <option key={cate._id} value={cate._id}>{cate.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Sub Category</label>
                                                <select class="form-control" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} name="subcategory" value={this.state.subcategory} onChange={this.handleTextChange}  required>
                                                    <option value="">Select Sub Category</option>
                                                    {_.filter(blogsubcategories,{ 'category_id': this.state.category }).map((subcate)=>{
                                                        return(
                                                            <option key={subcate._id} value={subcate._id}>{subcate.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                

                                            </div>
                                        </div> */}


                                        

                                       
                                        
                                        {/* <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Thumbnail Image URL</label>
                                                    <input type="text" className="form-control" name="thumbnail_image" value={this.state.thumbnail_image} onChange={this.handleTextChange}  required/>
                                                   
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Breadcrumb Image URL</label>
                                                    <input type="text" className="form-control" name="breadcrumb_image" value={this.state.breadcrumb_image} onChange={this.handleTextChange}  required/>
                                                   
                                                </div>
                                            </div> */}


                                            {/* <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Breadcrumb Color</label>
                                                    <input type="color" className="form-control" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} name="banner_color" value={this.state.banner_color} onChange={this.handleTextChange}  required/>
                                                </div>
                                            </div> */}


                                            {/* <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Blog image URL</label>
                                                    <input type="text" className="form-control" name="banner_image" value={this.state.banner_image} onChange={this.handleTextChange}  />
                                                    
                                                </div>
                                            </div> */}


                                        <div className='col-md-4 mt-2 mb-4 cursor-pointer' onClick={()=>this.handleSelectImagePopup('thumbnail_image')}>
                                            <label>Blog Image</label>
                                            {this.state.thumbnail_image===''
                                            ?
                                            <div className='imagebox458'>
                                                <center>Thumbnail Image</center>
                                            </div>
                                            :
                                            <img src={this.state.thumbnail_image+'?tr=w-200,h-200,q-50'} className='cursor-pointer' style={{width:'100%',maxHeight:'200px'}} />
                                            }
                                        </div>
                                        <div className='col-md-4 mt-2 mb-4 cursor-pointer'>
                                            <label>Preview</label>
                                            {this.state.thumbnail_image===''
                                            ?
                                            <div className='imagebox458'  style={{backgroundColor:this.state.banner_color}}>
                                                <center>Preview</center>
                                            </div>
                                            :
                                            <div  style={{backgroundColor:this.state.banner_color}}>
                                                <img src={this.state.thumbnail_image+'?tr=w-200,h-200,q-50'} className='cursor-pointer' style={{width:'100%',maxHeight:'200px'}} />
                                            </div>
                                            
                                            }
                                        </div>
                                        
                                        <div className='col-md-4 mt-2 mb-4'>
                                            <div className="form-group">
                                                <label>Image Title</label>
                                                <input type="text" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className="form-control" name="imagetitle" value={this.state.imagetitle} onChange={this.handleTextChange}  required/>
                                                {/* <h6 className="text-danger">{this.validator.message('meta title', this.state.metatitle, 'required')}</h6> */}

                                            </div>
                                            <div className="form-group">
                                                <label>Image ALT</label>
                                                <input type="text" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className="form-control" name="imagealt" value={this.state.imagealt} onChange={this.handleTextChange}  required/>
                                                {/* <h6 className="text-danger">{this.validator.message('meta title', this.state.metatitle, 'required')}</h6> */}
                                            </div>
                                            <div className="form-group">
                                                <label>Breadcrumb Color</label>
                                                <input type="color" className="form-control" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} name="banner_color" value={this.state.banner_color} onChange={this.handleTextChange}  required/>
                                            </div>

                                        </div>




                                        <div className="col-md-12">
                                            <label>Content (Readingtime: {this.state.contentreadtime} min)</label>
                                            <CKEditor
                                                onChange={this.onEditorChange}
                                                data={this.state.content}
                                                config={{
                                                    width: '100%',
                                                    height: '150px',
                                                }}
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <br/>
                                            <hr/>
                                            <br/>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Meta Title</label>
                                                <input type="text" className="form-control" name="metatitle" value={this.state.metatitle} onChange={this.handleTextChange} required/>
                                                {/* <h6 className="text-danger">{this.validator.message('meta title', this.state.metatitle, 'required')}</h6> */}

                                            </div>
                                            <div className="form-group">
                                                <label>Meta Description</label>
                                                <textarea className="form-control" rows="3" name="metadescription" value={this.state.metadescription} onChange={this.handleTextChange} required></textarea>
                                                {/* <h6 className="text-danger">{this.validator.message('meta descripation', this.state.metadescription, 'required|min:10|max:120')}</h6> */}
                                            </div>
                                            <div className="form-group">
                                                <label>Meta Keyword</label>
                                                <p>
                                                    {this.state.metakey_array.map((mt,key)=>{
                                                        return(
                                                            <span key={key} className='mr-2'>
                                                                 <span className='metakey11'>{mt} <span className='metakey11_X' onClick={()=>this.removeArray(mt)}>X</span></span>
                                                            </span>
                                                        )
                                                    })}
                                                    
                                                </p>
                                                {/* <textarea className="form-control" rows="3" name="metakey" value={this.state.metakey} onChange={this.handleTextChange}  required></textarea> */}
                                                <div style={{display:'inline-flex'}}>
                                                <input type='text' className="form-control" id='metaley77' /><button type='button' className='btn btn-primary btn-sm  ml-1' onClick={this.handleAddMetaKey}>+</button>
                                                </div>
                                            </div> 
                                            
                                        </div>
                                        <div className="col-md-6">
                                            <p className="blogseo_metalink">{`blog.qtonix.com/${this.state.url}`}</p>
                                            <p className="blogseo_metatitle">{(this.state.metatitle === '' ? <>Meta Title</> : this.state.metatitle)}</p>
                                            <p className="blogseo_metadesc">{(this.state.metadesc === '' ? <>Meta Description</> : this.state.metadescription)} </p>
                                            <p className="blogseo_metakey">{(this.state.metakey === '' ? <></> : this.state.metakey)} </p>
                                                    
                                        </div>

                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                        </div>
                                    </div>
                                </form>


                                {/* <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label><span className="text-danger"></span>Title</label>
                                                <input type="text" className="form-control" name="title" value={this.state.title} onChange={this.handleTextChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <textarea className="form-control" rows="3" name="description" value={this.state.description} onChange={this.handleTextChange}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Category</label>
                                                <select class="form-control" name="category" value={this.state.category} onChange={this.handleTextChange}>
                                                    <option value="">Select Category</option>
                                                    {blogcategories.map((cate)=>{
                                                        return(
                                                            <option key={cate._id}>{cate.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Blog Image</label>
                                                <input type="file" className="filestyle" data-buttonname="btn-secondary"  onChange={this.handleImageChange} />
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12">
                                            <label>Content</label>
                                            <CKEditor
                                                onChange={this.onEditorChange}
                                                data={this.state.content}
                                                config={{
                                                    width: '100%',
                                                    height: '150px',
                                                }}
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <br/>
                                            <hr/>
                                            <br/>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Meta Title</label>
                                                <input type="text" className="form-control" name="metatitle" value={this.state.metatitle} onChange={this.handleTextChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Meta Description</label>
                                                <textarea className="form-control" rows="3" maxLength={200} name="metadescription" value={this.state.metadescription} onChange={this.handleTextChange}></textarea>
                                            </div>  
                                            <div className="form-group">
                                                <label>Meta Keyword</label>
                                                <textarea className="form-control" rows="3" maxLength={200} name="metakey" value={this.state.metakey} onChange={this.handleTextChange}></textarea>
                                            </div>

                                            
                                            
                                        </div>
                                        <div className="col-md-6">
                                            <p className="blogseo_metalink">{(this.state.title === '' ? <>www.website.com</> : process.env.REACT_APP_WEBSITEURL+'/'+this.state.category.toLowerCase()+'/'+this.state.url )}</p>
                                            <p className="blogseo_metatitle">{(this.state.metatitle === '' ? <>Lorem ipsum dolor sit amet, consectetur adipiscing</> : this.state.metatitle)}</p>
                                            <p className="blogseo_metadesc">{(this.state.metadescription === '' ? <>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</> : this.state.metadescription)} </p>
                                        </div>

                                        <div className="col-md-12">
                                            <button className="btn btn-primary btn-block">Submit</button>
                                        </div>
                                    </div>
                                </form> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
            }
            </div>
            <Footer />
            </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    blogcategories:state.blogcategories,
    blogsubcategories:state.blogsubcategories,
    
})

export default connect(mapStateToProps, {fetchAllBlogCategory,fetchAllBlogSubCategory})(BlogEdit)
