import React, { Component } from 'react'
import cookie from 'react-cookies'
import { store } from 'react-notifications-component';
import { withRouter } from 'react-router-dom'
import {Helmet} from "react-helmet";
import { MdSettings,MdCropFree,MdNotifications,MdLockOpen, } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";

class TopBar extends Component {

 
  handleLogout = () => {
    cookie.remove('userlogin',{path:'/'})
    cookie.remove('userdata',{path:'/'})
    

    // store.addNotification({
    //   title: 'Well done',
    //   message: 'You successfully logged out from this website',
    //   type: 'success',                         // 'default', 'success', 'info', 'warning'
    //   container: 'top-right',                // where to position the notifications
    //   animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
    //   animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
    //   dismiss: {
    //     duration: 3000
    //   }
    // })

    this.props.history.push(`/login/`, null);
  }

    render() {
      const user = cookie.load('userdata');
        return (
            <>
            <Helmet>
              <title> 
              { ` QtonixBlog - ${ this.props.pagename }` }
              </title>
            </Helmet>
                {/* Top Bar Start */}
            <div className="topbar">
              <nav className="navbar-custom">
                {/* Search input */}
                <div className="search-wrap" id="search-wrap">
                  <div className="search-bar">
                    <input className="search-input" type="search" placeholder="Search" />
                    <a href="#" className="close-search toggle-search" data-target="#search-wrap">
                      <i className="mdi mdi-close-circle" />
                    </a>
                  </div>
                </div>
                <ul className="list-inline float-right mb-0">

                  <li className="list-inline-item dropdown notification-list hidden-xs-down">
                    <a className="nav-link waves-effect text-primary" >
                      {user.name}
                    </a>
                  </li>

                  <li className="list-inline-item dropdown notification-list hidden-xs-down">
                    <a className="nav-link waves-effect" id="btn-fullscreen">
                      <MdCropFree />
                    </a>
                  </li>

                  

                  {/* <li className="list-inline-item dropdown notification-list hidden-xs-down">
                    <a className="nav-link waves-effect text-primary" onClick={this.handleLogout}>
                      <AiOutlineLogout />
                    </a>
                  </li> */}

                  





                 
                  {/* User*/}
                  <li className="list-inline-item dropdown notification-list">
                    <a className="nav-link dropdown-toggle arrow-none waves-effect nav-user" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                      <MdSettings />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                      <a className="dropdown-item" onClick={this.handleLogout}><MdLockOpen /> Logout</a>
                    </div>
                  </li>
                </ul>
                {/* Page title */}
                <ul className="list-inline menu-left mb-0">
                  <li className="list-inline-item">
                    <button type="button" className="button-menu-mobile open-left waves-effect">
                      <i className="ion-navicon" />
                    </button>
                  </li>
                  <li className="hide-phone list-inline-item app-search">
                    <h3 className="page-title">{this.props.pagename}</h3>
                  </li>
                </ul>
                <div className="clearfix" />
              </nav>
            </div>
            {/* Top Bar End */}

            </>

        )
    }
}

export default withRouter(TopBar);
